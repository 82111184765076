import { FC, ReactElement } from "react";
import { Box, List, ListItem, Stack, styled, Typography } from "@mui/material";

import logo from "../../assets/images/logos/newLogo.svg";
import landing_showcase from "../../assets/images/landing-showcase.png";

const DotDivider = styled(Box)({
  width: 4,
  height: 4,
  borderRadius: "50%",
  backgroundColor: "#E4FAFB",
});

const StyledListItem = styled(ListItem)({
  paddingInline: 10,
});

const NoPaddingListItem = styled(ListItem)({
  padding: "0 !important",
});

const StyledListText = styled(Typography)(({ theme }) => ({
  color: "#E4FAFB",
  fontWeight: 400,
  fontFamily: "Inter",
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
  },
}));

const LandingPage: FC = (): ReactElement => {
  return (
    <Stack
      alignItems="center"
      pt={2.5}
      p={1}
      sx={{
        boxSizing: "border-box",
        height: {
          xs: "100%",
          sm: "calc(100% + 72px)", // full height with footer height for overflow effect
        },
        background: `url(${landing_showcase})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: {
          xs: 400,
          sm: 700,
        },
        backgroundPositionX: "center",
        backgroundPositionY: "bottom",
      }}
    >
      <Box component="img" src={logo} width={75} />
      <Typography
        color="#fff"
        fontWeight={700}
        textAlign="center"
        sx={{
          fontSize: {
            xs: "24px",
            sm: "40px",
          },
          mt: { xs: 3, sm: 1 },
        }}
      >
        Maximize your Duty Savings
      </Typography>
      <List dense sx={{ display: "flex", mt: { xs: 5, sm: 1 } }}>
        <NoPaddingListItem>
          <StyledListText>Visibility</StyledListText>
        </NoPaddingListItem>
        <StyledListItem>
          <DotDivider />
        </StyledListItem>
        <NoPaddingListItem>
          <StyledListText>Compliance</StyledListText>
        </NoPaddingListItem>
        <StyledListItem>
          <DotDivider />
        </StyledListItem>
        <NoPaddingListItem>
          <StyledListText>Savings</StyledListText>
        </NoPaddingListItem>
      </List>
      {/* bottom shadow */}
      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(3, 17, 18, 0.00) 0%, rgba(3, 17, 18, 0.74) 44.5%, #031112 73.5%)",
          width: "100%",
          height: "400px",
          position: "absolute",
          left: 0,
          bottom: 0,
        }}
      />
    </Stack>
  );
};

export default LandingPage;
