import { FC, ReactElement, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";

import LandingHeader from "../../components/landing-header";
import LandingFooter from "../../components/landing-footer";
import ContactModal from "../../components/contact-modal";

const LandingLayout: FC = (): ReactElement => {
  const [showDemoModal, setShowDemoModal] = useState<boolean>(false);

  const openModal = () => {
    setShowDemoModal(true);
  };

  const closeModal = () => {
    setShowDemoModal(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh",
        overflow: "hidden",
        background: "#031112",
        position: "relative",
      }}
    >
      <Stack maxWidth={1250} m="auto" height="100%">
        <LandingHeader onClickDemo={openModal} />
        <Box flex={1} zIndex={1}>
          <Outlet />
        </Box>
        <LandingFooter />
        <ContactModal open={showDemoModal} onClose={closeModal} />
      </Stack>
      {/* center shadow */}
      <Box
        sx={{
          background:
            "linear-gradient(149deg, #1CB4BA 12.9%, #64E3E8 29.15%, #1DBF73 41.54%, #4F6AFF 54.7%, #42D77E 70.18%, rgba(0, 192, 255, 0.15) 87.21%)",
          filter: "blur(82px)",
          width: "50%",
          height: "40%",
          borderRadius: "50%",
          position: "absolute",
          top: { xs: "80%", sm: "60%" },
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  );
};

export default LandingLayout;
