import { FC, ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import app_logo_white from "../../assets/images/logos/logo-white.svg";

const StyledButton = styled(Button)({
  color: "#fff",
  fontSize: 14,
  fontWeight: 500,
  padding: "10px 24px",
  borderRadius: "6px",
  textTransform: "none",
});

const LandingHeader: FC<{ onClickDemo: () => void }> = ({
  onClickDemo,
}): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const changeDrawerVisibility = (drawerStatus: boolean) => () => {
    setIsDrawerOpen(drawerStatus);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="1.5rem 1rem"
    >
      <Box component="img" src={app_logo_white} width={180} />
      <IconButton
        sx={{
          display: { xs: "inline-flex", sm: "none" },
        }}
        onClick={changeDrawerVisibility(true)}
      >
        <MenuIcon sx={{ color: "#fff" }} />
      </IconButton>
      <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: 250,
            background: "#0b494b",
            boxShadow: "2px 2px 2.5px rgba(255, 255, 255, 0.15)",
          },
        }}
        open={isDrawerOpen}
        onClose={changeDrawerVisibility(false)}
      >
        <Box
          component="img"
          src={app_logo_white}
          sx={{ padding: "24px", paddingBottom: "12px" }}
        />
        <List
          sx={{
            "& .MuiListItem-root": {
              padding: "4px 10px",
            },
            "& .MuiTypography-root": { fontSize: 14, color: "#fff" },
          }}
        >
          {["Careers", "Legal", "Support"].map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton LinkComponent={Link} href="#">
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider color="#397779" />
        <List
          sx={{
            marginTop: 1,
            "& .MuiListItem-root": {
              padding: "5px 10px",

              "& .MuiButtonBase-root": {
                background: "#07383c",
                borderRadius: 2,
                "&:hover": {
                  background: "#07383c",
                },
              },
            },

            "& .MuiTypography-root": { color: "#fff", fontSize: 12 },
          }}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{}} component={Link} to="/login">
              <ListItemText primary="Login" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                background: "#0B494B",
                borderRadius: 2,
                "&:hover": {
                  background: "#0B494B",
                },
              }}
              onClick={onClickDemo}
            >
              <ListItemText primary="See a Demo" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1.5}
        sx={{
          display: { xs: "none", sm: "flex" },
        }}
      >
        <StyledButton
          variant="contained"
          sx={{
            background: "rgba(255, 255, 255, 0.15)",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
          component={Link}
          to="/login"
        >
          Login
        </StyledButton>
        <StyledButton
          variant="contained"
          sx={{
            background: "#0B494B",
            "&:hover": {
              background: "rgba(11, 73, 75, 0.50)",
            },
          }}
          onClick={onClickDemo}
        >
          See a Demo
        </StyledButton>
      </Stack>
    </Stack>
  );
};

export default LandingHeader;
