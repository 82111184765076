import PageNotFound from "../error-pages/NotFound";
import { Routes, Route, useNavigate } from "react-router-dom";
import { routes, adminRoutes } from "../config/routes-config";
import PrivateRoute from "./PrivateRoute";
import Login from "../main/auth/Login";
import { useContext, useEffect } from "react";
import UserContext from "../context/user/UserContext";
import { UserContextType } from "../@types/UserContext";
import LandingLayout from "./landing";
import LandingPage from "../main/landing";

const Router = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext) as UserContextType;

  useEffect(() => {
    if (userContext.isAuthenticated()) {
      navigate("/dashboard/analytics");
    }
  }, []);

  return (
    <Routes>
      <Route path="/landing" element={<LandingLayout />} key="Landing">
        {/* This is the default route under /landing */}
        <Route index element={<LandingPage />} />
      </Route>
      <Route path="/login" element={<Login />} key="Login" />
      {routes.map((route) => (
        <Route
          path={route.route}
          element={<PrivateRoute component={route.component} />}
          key={route.key}
        />
      ))}
      {userContext.isAdmin() ? (
        adminRoutes.map((route) => (
          <Route
            path={route.route}
            element={<PrivateRoute component={route.component} />}
            key={route.key}
          />
        ))
      ) : (
        <></>
      )}
      <Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
