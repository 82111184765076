import { useState, useContext, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Stack,
  Box,
  InputAdornment,
  IconButton,
  FormLabel,
  styled,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../context/user/UserContext";
import AuthContainer from "../../main/AuthContainer";
import { UserContextType } from "../../@types/UserContext";
import GlobalMessageContext from "../../context/globalMessage/GlobalMessageContext";
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext";
import { ENDPOINT } from "../../config/config";
import axios from "axios";
import { ErrorLoadingType } from "../../utils/Error";
import { useForm } from "react-hook-form";
import { FormInputProps, FormInputText } from "../form/FormInputText";
import { Checkbox } from "../../components";
import GlobalSnackBarMessage from "../../common/GlobalSnackBarMessage";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const StyledLabel = styled(FormLabel)({
  color: "#030712",
  fontSize: 14,
  fontWeight: 500,
  fontFamily: "Inter",
});

const StyledInput = ({ ...props }: FormInputProps) => (
  <FormInputText
    {...props}
    sx={{
      background: "#fff",
      "& fieldset": {
        border: "1px solid #E6E6E9",
        borderRadius: "8px",
      },
      "& input::placeholder": {
        color: "#ADAFB9",
      },
    }}
  />
);

type LoginInput = {
  username: string;
  password: string;
};

const defaultLoginValues = {
  username: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [remember, setRemember] = useState<boolean>(false);
  const userContext = useContext(UserContext) as UserContextType;
  const { updateSnackBar } = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType;
  const [errorLoading, setErrorLoading] = useState<ErrorLoadingType>({
    isError: false,
    isLoading: false,
    errorMsg: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm<LoginInput>({ defaultValues: defaultLoginValues });
  const { handleSubmit, control, setError } = methods;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (data: LoginInput) => {
    if (!data.username) {
      setError("username", { message: "Username is required" });
      return;
    }
    if (!data.password) {
      setError("password", { message: "Password is required" });
      return;
    }
    login(data);
  };

  const login = async (data: LoginInput) => {
    try {
      setErrorLoading({ isLoading: true, isError: false });
      const res = await axios.post(`${ENDPOINT}/login`, {
        username: data.username,
        password: data.password,
      });
      userContext.updateUser({
        token: res?.data?.access_token,
        user: res?.data?.user,
      });

      setErrorLoading({ isLoading: false, isError: false });
      navigate("/");
    } catch (err: any) {
      setErrorLoading({
        isLoading: false,
        isError: true,
        errorMsg: err?.response?.data?.error,
      });
    }
  };
  useEffect(() => {
    if (errorLoading.isError) {
      updateSnackBar({
        show: true,
        variant: "error",
        message:
          errorLoading.errorMsg ??
          "Something went wrong. Please check your credentials and try again.",
      });
    }
  }, [errorLoading.isError]);
  return (
    <AuthContainer
      children={
        <Stack height={"100%"} overflow={"auto"} px={{ xs: 3, sm: 6 }}>
          <GlobalSnackBarMessage />
          <Stack
            direction={"column"}
            justifyContent={"center"}
            sx={{
              position: "relative",
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", mx: "auto", mt: 5 }}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  variant="h3"
                  fontWeight={600}
                  color="#031112"
                  fontSize={32}
                  sx={{
                    fontSize: { xs: 28, sm: 32 },
                  }}
                >
                  Welcome to Tradedive
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="14px"
                  mt={0.5}
                  fontWeight={400}
                  color="#8E8F96"
                >
                  Sign in to your account
                </Typography>
                <Box sx={{ mt: 3.5, mb: 3 }}>
                  <Stack spacing={1}>
                    <Box>
                      <StyledLabel htmlFor="username">Email</StyledLabel>
                      <StyledInput
                        variant="outlined"
                        id="username"
                        name="username"
                        control={control}
                        placeholder="hi@example.com"
                        isRequired={true}
                        type="text"
                        size="medium"
                        color="secondary"
                      />
                    </Box>
                    <Box sx={{ pt: 1 }}>
                      <StyledLabel htmlFor="password">Password</StyledLabel>
                      <StyledInput
                        id="password"
                        variant="outlined"
                        name="password"
                        control={control}
                        isRequired={true}
                        type={showPassword ? "text" : "password"}
                        size="medium"
                        color="secondary"
                        placeholder="password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Checkbox
                        label="Remember Me"
                        color="secondary"
                        checked={remember}
                        onChange={() => setRemember(!remember)}
                      />
                      <Typography
                        component={Link}
                        to="#"
                        color="#0B494B"
                        fontSize="14px"
                        fontWeight={600}
                        fontFamily="Inter"
                        sx={{
                          textDecoration: "none",
                        }}
                      >
                        Forgot Password
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={4}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "6px",
                        width: "100%",
                        fontWeight: 500,
                        fontSize: 16,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "none",
                        gap: 2,
                        pt: 1.8,
                        pb: 1.5,
                        px: 4,
                        mt: { xs: 2, sm: 0 },
                      }}
                      color="secondary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      disableElevation
                      disabled={errorLoading.isLoading}
                    >
                      {errorLoading.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
      }
    />
  );
};

export default Login;
