import { Box, CircularProgress, Grid } from "@mui/material";
import Opportunities from "./Opportunities";
import Vendors from "./Vendors";
import { useContext, useEffect, useState } from "react";
import {
  VendorsDataType,
  VendorsType,
} from "../../../@types/PotentialDashboard";
import { UserContextType } from "../../../@types/UserContext";
import UserContext from "../../../context/user/UserContext";
import { ENDPOINT } from "../../../config/config";
import axios from "axios";

interface IProps {
  dates: any;
  page: any;
  dutySpendFilter: any;
  pageSize: any;
  setPage: any;
}

const FirstSaleTab = ({
  dates,
  page,
  dutySpendFilter,
  pageSize,
  setPage,
}: IProps) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [lastUpdatedDate, setLastUpdatedDate] = useState<string>("");
  const [state, setState] = useState(false);
  const [vendors, setVendors] = useState<VendorsDataType>();
  const [relatedVendors, setRelatedVendors] = useState<VendorsDataType>();
  const [unrelatedVendors, setUnrelatedVendors] = useState<VendorsDataType>();
  const [opportunities, setOpportunities] = useState<VendorsDataType>();
  const [originVsExport, setOriginVsExport] = useState<VendorsDataType>();
  const [originVsManufacturer, setOriginVsManufacturer] =
    useState<VendorsDataType>();
  const [filterLabel, setFilterLabel] = useState<string>("Today");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [dutySpendFilter, dates]);

  const fetchData = async () => {
    const startDate = dates?.startDate ?? "";
    const endDate = dates?.endDate ?? "";
    try {
      setLoading(true);
      const promise1 = axios.get(
        `${ENDPOINT}/dashboard-potential/vendors?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise2 = axios.get(
        `${ENDPOINT}/dashboard-potential/vendors/related?dateFrom=${startDate}&dateTo=${endDate}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise3 = axios.get(
        `${ENDPOINT}/dashboard-potential/vendors/unrelated?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise4 = axios.get(`${ENDPOINT}/data/last-update`, {
        headers: {
          authorization: `Bearer ${userContext?.user?.token}`,
        },
      });

      const promise5 = axios.get(
        `${ENDPOINT}/dashboard-potential/opportunities?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}&pageSize=${10}&pageNo=${0}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise6 = axios.get(
        `${ENDPOINT}/dashboard-potential/origin/exporter?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}&pageSize=${10}&pageNo=${0}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );
      const promise7 = axios.get(
        `${ENDPOINT}/dashboard-potential/origin/manufacturer?dateFrom=${startDate}&dateTo=${endDate}&attribute=${dutySpendFilter}&pageSize=${10}&pageNo=${0}`,
        {
          headers: {
            authorization: `Bearer ${userContext?.user?.token}`,
          },
        }
      );

      const [
        result1,
        result2,
        result3,
        result4,
        result5,
        result6,
        result7,
      ]: any = await Promise.allSettled([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
      ]);

      setVendors(result1?.value?.data);
      setRelatedVendors(result2?.value?.data);
      setUnrelatedVendors(result3?.value?.data);
      setLastUpdatedDate(result4?.value?.data?.lastUpdatedAt);
      setOpportunities(result5?.value?.data);
      setOriginVsExport(result6?.value?.data);
      setOriginVsManufacturer(result7?.value?.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2rem",
          height: "74vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const mapOpportunities = (dataList: VendorsType[]) => {
    if (!dataList?.length) return [];
    // const firstFiveOpportunities = dataList.slice(0, 5);
    return dataList.map((vendor) => ({
      duty: vendor.tariff_duty,
      manufacturer: vendor.manufacturer,
    }));
  };

  const getOpportunitiesProps = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return {
          data: mapOpportunities(opportunities?.data ?? []),
          totalDuty: opportunities?.total?.customs_duty ?? 0,
        };
      case 1:
        return {
          data: mapOpportunities(originVsExport?.data ?? []),
          totalDuty: originVsExport?.total?.customs_duty ?? 0,
        };
      case 2:
        return {
          data: mapOpportunities(originVsManufacturer?.data ?? []),
          totalDuty: originVsManufacturer?.total?.customs_duty ?? 0,
        };
      default:
        return {
          data: [],
          totalDuty: 0,
        };
    }
  };

  const opportunitiesProps = getOpportunitiesProps(tabValue);
  return (
    <Grid container spacing={4}>
      <Grid item sm={6} xs={12}>
        <Vendors
          headingTxt="Manufacturers"
          relatedVendors={relatedVendors}
          vendorsData={vendors}
          unrelatedVendors={unrelatedVendors}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Opportunities
          subHeadingTxt=""
          data={opportunitiesProps.data}
          totalDuty={opportunitiesProps.totalDuty}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
        />
      </Grid>
      {/* <Grid item sm={6} xs={12}>
        <Vendors headingTxt="Related Vendors" vendorsData={relatedVendors} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Opportunities
          subHeadingTxt="Country of Origin and Export do not match"
          data={mapOpportunities(originVsExport?.data ?? [])}
          totalDuty={originVsExport?.total?.customs_duty ?? 0}
        />
      </Grid>
    <Grid item sm={6} xs={12}>
        <Vendors
          headingTxt="Unrelated Vendors"
          vendorsData={unrelatedVendors}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Opportunities
          subHeadingTxt="Manufacturer base and Country of Origin do not match"
          data={mapOpportunities(originVsManufacturer?.data ?? [])}
          totalDuty={originVsManufacturer?.total?.customs_duty ?? 0}
        />
      </Grid> */}
    </Grid>
  );
};

export default FirstSaleTab;
