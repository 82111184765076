import { FC, forwardRef, ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  FormLabel,
  Slide,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import { FormInputProps, FormInputText } from "../../main/form/FormInputText";
import modal_close_icon from "../../assets/images/modal-close-icon.svg";
import { TransitionProps } from "@mui/material/transitions";

type Input = {
  name: string;
  email: string;
  companyName: string;
};

const defaultValues = {
  name: "",
  email: "",
  companyName: "",
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CardGradient = styled(Box)({
  width: "100%",
  height: "165px",
  background:
    "linear-gradient(180deg, #697A7B 0%, rgba(205, 211, 211, 0.00) 78%)",
  position: "absolute",
  top: 0,
  left: 0,
});

const StyledCloseButton = styled(Button)({
  padding: 0,
  minWidth: "initial",
  width: "fit-content",
  position: "absolute",
  top: 16,
  right: 24,
  borderRadius: "5px",
  zIndex: 2,
});

const StyledButton = styled(Button)({
  padding: "12px 35px",
  border: "1px solid #E6E6E9",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "none",
  color: "#052223",
  whiteSpace: "nowrap",

  "&.active": {
    background: "rgba(5, 34, 35, 0.12)",
    borderColor: "#052223",
  },
});

const StyledLabel = styled(FormLabel)({
  color: "#030712",
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 8,
});

const StyledInput = ({ ...props }: FormInputProps) => (
  <FormInputText
    {...props}
    sx={{
      marginBottom: "14px",
      background: "#fff",
      "& fieldset": {
        border: "1px solid #E6E6E9",
        borderRadius: "8px",
      },
      "& input::placeholder": {
        color: "#ADAFB9",
      },
    }}
  />
);

const SubmitButton = styled(Button)({
  background: "#0B494B",
  color: "#fff",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "none",
  borderRadius: "8px",
  padding: "12px 16px",
  marginTop: 14,

  "&:hover": {
    background: "#093A3B",
  },
});

const ContactModal: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }): ReactElement => {
  const [firmType, setFirmType] = useState<"importer" | "serviceProvider">(
    "importer"
  );

  const { handleSubmit, control, setError } = useForm<Input>({
    defaultValues: defaultValues,
  });

  const onSubmit = (values: Input) => {
    console.log("form values ===>", values);
    onClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: 470,
          position: "relative",
          padding: {
            xs: "1rem",
            sm: "28px 24px",
          },
          margin: {
            xs: "0",
            sm: 2,
          },
        },
      }}
    >
      <CardGradient />
      <StyledCloseButton onClick={onClose}>
        <Box
          component="img"
          src={modal_close_icon}
          sx={{ width: 26, height: 26 }}
        />
      </StyledCloseButton>
      <Typography color="#030712" fontWeight={500} fontSize={24} zIndex={1}>
        ☎️ Contact Sales
      </Typography>
      <Typography
        color="#8C8E9C"
        fontWeight={400}
        fontSize={12}
        mb={2.5}
      >
        Tell us about your firm or company and our team will reach out to set up
        a call.
      </Typography>
      <StyledLabel>Firm Type*</StyledLabel>
      <Stack direction="row" spacing={1.5} mb="14px">
        <StyledButton
          onClick={() => setFirmType("importer")}
          className={firmType === "importer" ? "active" : ""}
        >
          Importer
        </StyledButton>
        <StyledButton
          onClick={() => setFirmType("serviceProvider")}
          className={firmType === "serviceProvider" ? "active" : ""}
        >
          Service Provider
        </StyledButton>
      </Stack>
      <StyledLabel htmlFor="name">Your Name</StyledLabel>
      <StyledInput
        variant="outlined"
        id="name"
        name="name"
        control={control}
        placeholder="eg Alex Sam"
        type="text"
        size="medium"
        color="secondary"
      />
      <StyledLabel htmlFor="email">Email*</StyledLabel>
      <StyledInput
        variant="outlined"
        id="email"
        name="email"
        control={control}
        placeholder="hi@example.com"
        type="email"
        size="medium"
        color="secondary"
        isRequired
      />
      <StyledLabel htmlFor="companyName">Company Name</StyledLabel>
      <StyledInput
        variant="outlined"
        id="companyName"
        name="companyName"
        control={control}
        placeholder="eg Broadgage"
        type="text"
        size="medium"
        color="secondary"
        isRequired
      />
      <SubmitButton onClick={handleSubmit(onSubmit)}>Submit</SubmitButton>
    </Dialog>
  );
};

export default ContactModal;
