import { FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Stack, styled, Typography } from "@mui/material";

const StyledText = styled(Typography)({
  textDecoration: "none",
  color: "#CAF5F7",
  fontSize: 16,
  fontWeight: 400,
});

const LandingFooter: FC = (): ReactElement => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="1.5rem 1rem"
      zIndex={1}
    >
      <StyledText
        sx={{
          textAlign: {
            xs: "center",
            sm: "left",
          },
          flex: {
            xs: 1,
            sm: "none",
          },
        }}
      >
        © 2024 Tradedive Inc. All Rights Reserved.
      </StyledText>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={3}
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
        }}
      >
        <StyledText component={Link} to="#">
          Careers
        </StyledText>
        <StyledText component={Link} to="#">
          Legal
        </StyledText>
        <StyledText component={Link} to="#">
          Support
        </StyledText>
      </Stack>
    </Stack>
  );
};

export default LandingFooter;
