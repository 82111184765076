import {
  Box,
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import ChinaIcon from "../../../assets/images/china.svg";
import BanglaIcon from "../../../assets/images/bangla.svg";
import EgyptIcon from "../../../assets/images/egypt.svg";
import VietIcon from "../../../assets/images/viet.svg";
import IndoIcon from "../../../assets/images/indo.svg";
import PhilipIcon from "../../../assets/images/philip.svg";
import IndiaIcon from "../../../assets/images/india.svg";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { CiSquarePlus } from "react-icons/ci";
import { useState } from "react";
function createData(
  countryOfOrigin: IKeyValue,
  customValue: string,
  customDuty: string,
  distinctImports: number,
  distinctSuppliers: number,
  effectiveDutyRate: string,
  avgShipmentRate: string,
  dutySaved: string
) {
  return {
    countryOfOrigin,
    customValue,
    customDuty,
    distinctImports,
    distinctSuppliers,
    effectiveDutyRate,
    avgShipmentRate,
    dutySaved,
    history: [
      {
        countryOfOrigin: "China",
        customValue: "214,034,447.00",
        customDuty: "214,034,447.00",
        distinctImports: 4805,
        distinctSuppliers: 4805,
        effectiveDutyRate: "14.71%",
        avgShipmentRate: "44,544.11",
        subRowsData: [
          {
            countryOfOrigin: "Pakistan",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
          {
            countryOfOrigin: "India",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
        ],
      },
      {
        countryOfOrigin: "France",
        customValue: "214,034,447.00",
        customDuty: "214,034,447.00",
        distinctImports: 4805,
        distinctSuppliers: 4805,
        effectiveDutyRate: "14.71%",
        avgShipmentRate: "44,544.11",
        subRowsData: [
          {
            countryOfOrigin: "America",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
          {
            countryOfOrigin: "Italy",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
        ],
      },
    ],
  };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Row = (props: { row: IKeyValue }) => {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            display: "flex",
            gap: 2,
            alignItems: "center",
            pl: 1,
          }}
        >
          <Box display="flex" gap={0.5} alignItems={"center"}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <CiSquarePlus fontSize={25} color="#E1E1E1" />
            </IconButton>

            <img src={row?.countryOfOrigin?.icon} alt="" />
          </Box>
          <Typography fontWeight={500} color="#020202" mb={0} pb={0}>
            {row?.countryOfOrigin?.text}
          </Typography>
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          $ {row?.customValue}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          $ {row?.customDuty}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {row?.distinctImports}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {row?.distinctSuppliers}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {row?.effectiveDutyRate}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {row?.avgShipmentRate}
        </TableCell>
        <TableCell
          style={{
            fontWeight: 500,
            color: "#000",
            borderBottom: "1px solid #E8E8E8",
          }}
        >
          $ {row?.dutySaved}
        </TableCell>
      </StyledTableRow>
    </>
  );
};

const EnteredValue = () => {
  const rows = [
    createData(
      { text: "China", icon: ChinaIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
    createData(
      { text: "Bangladesh", icon: BanglaIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
    createData(
      { text: "Egypt", icon: EgyptIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
    createData(
      { text: "Viet Nam", icon: VietIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
    createData(
      { text: "Indonesia", icon: IndoIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
    createData(
      { text: "Philippines", icon: PhilipIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
    createData(
      { text: "India", icon: IndiaIcon },
      "214,034,447.00",
      "214,034,447.00",
      4805,
      4805,
      "14.71%",
      "44,544.11",
      "6,546,657.04"
    ),
  ];

  return (
    <Card sx={{ px: 3, pt: 2, pb: 1 }}>
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography
          color="#000"
          fontSize={"1.2rem"}
          fontWeight={500}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 7,
          }}
        >
          Entered Value
          <br />
        </Typography>
        <span
          style={{
            color: "#8E9494",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          by Country of Origin
        </span>
      </Box>
      <TableContainer
        className="product-table"
        component={Paper}
        style={{
          maxHeight: 440,
          borderRadius: "10px 10px 8px 8px",
          boxShadow: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "0px 10px",
                  fontSize: "14px",
                }}
              >
                Country of Origin
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Customs Value
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Customs Duty
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Distinct Imports
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Distinct Suppliers
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Effective Duty Rate
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Avg Shipment Value
              </TableCell>
              <TableCell
                sx={{
                  background: "#CEDBDB",
                  color: "#116B6F",
                  padding: "5px 10px",
                  fontSize: "14px",
                }}
              >
                Duty Saved
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: IKeyValue) => <Row key={row._id} row={row} />)}
            <StyledTableRow>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "500",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                $ 385,401,272.00
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                $ 51,774,674.82
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                7220
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                2136
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                13.43%
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                53.379.68
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  background: "#CEDBDB",
                  color: "#116B6F",
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1rem",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "2",
                }}
              >
                $ 9,591,349.73
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default EnteredValue;
