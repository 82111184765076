import {
  Box,
  Card,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { FlagIcon } from "react-flag-kit"; // If you're using a flag library
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { IKeyValue } from "../../../@types/GlobalMessageContext";
import { countries } from "../../../constants"; // If you're using a flag library
import { numberWithCommas } from "../../../utils/abbreviateNumber";
function createData(
  countryOfOrigin: IKeyValue,
  customValue: string,
  customDuty: string,
  distinctImports: number,
  distinctSuppliers: number,
  effectiveDutyRate: string,
  avgShipmentRate: string,
  dutySaved: string
) {
  return {
    countryOfOrigin,
    customValue,
    customDuty,
    distinctImports,
    distinctSuppliers,
    effectiveDutyRate,
    avgShipmentRate,
    dutySaved,
    history: [
      {
        countryOfOrigin: "China",
        customValue: "214,034,447.00",
        customDuty: "214,034,447.00",
        distinctImports: 4805,
        distinctSuppliers: 4805,
        effectiveDutyRate: "14.71%",
        avgShipmentRate: "44,544.11",
        subRowsData: [
          {
            countryOfOrigin: "Pakistan",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
          {
            countryOfOrigin: "India",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
        ],
      },
      {
        countryOfOrigin: "France",
        customValue: "214,034,447.00",
        customDuty: "214,034,447.00",
        distinctImports: 4805,
        distinctSuppliers: 4805,
        effectiveDutyRate: "14.71%",
        avgShipmentRate: "44,544.11",
        subRowsData: [
          {
            countryOfOrigin: "America",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
          {
            countryOfOrigin: "Italy",
            customValue: "214,034,447.00",
            customDuty: "214,034,447.00",
            distinctImports: 4805,
            distinctSuppliers: 4805,
            effectiveDutyRate: "14.71%",
            avgShipmentRate: "44,544.11",
          },
        ],
      },
    ],
  };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8F8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Row = (props: { row: IKeyValue; nestedData: IKeyValue[] }) => {
  const { row, nestedData } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            display: "flex",
            gap: 2,
            alignItems: "center",
            pl: 1,
          }}
        >
          <Box display="flex" gap={0.5} alignItems={"center"}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <CiSquareMinus fontSize={25} color="#E1E1E1" />
              ) : (
                <CiSquarePlus fontSize={25} color="#E1E1E1" />
              )}{" "}
            </IconButton>

            {/* <img src={countries[row?._id]?.icon} alt="" /> */}

            {countries.find((el: IKeyValue) => el?.shortForm === row?._id)
              ?.name ? (
              <FlagIcon style={{ borderRadius: "50px" }} code={row?._id} />
            ) : (
              ""
            )}
          </Box>
          <Typography fontWeight={500} color="#020202" mb={0} pb={0}>
            {countries.find((el: IKeyValue) => el?.shortForm === row?._id)
              ?.name ?? row?._id}
          </Typography>
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          $ {numberWithCommas(~~row?.custom_value)}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          $ {numberWithCommas(~~row?.custom_duty)}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {numberWithCommas(~~row?.distinct_imports)}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {numberWithCommas(~~row?.distinct_suppliers)}
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {~~(row?.effective_duty_rate * 100)}%
        </TableCell>
        <TableCell
          style={{
            borderRight: "1px solid #E8E8E8",
            borderBottom: "1px solid #E8E8E8",
            fontWeight: 500,
            color: "#000",
          }}
        >
          {/* {numberWithCommas(~~row?.avg_shipment_value)} */}
          {numberWithCommas(~~(row?.custom_value / row?.distinct_imports))}
        </TableCell>
        <TableCell
          style={{
            fontWeight: 500,
            color: "#000",
            borderBottom: "1px solid #E8E8E8",
          }}
        >
          $ {numberWithCommas(~~row?.duty_saved)}
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse
            in={open}
            key={Math.floor(1000 + Math.random() * 9000)}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {nestedData?.map((nestedRow: IKeyValue, ind: number) => (
                    <NestedRows nestedRow={nestedRow} nestedIndex={ind} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const NestedRows = ({
  nestedRow,
  nestedIndex,
}: {
  nestedRow: IKeyValue;
  nestedIndex: number;
}) => {
  const [subRowOpen, setSubRowOpen] = useState(false);

  return (
    <>
      <TableRow key={nestedRow._id}>
        <TableCell sx={{ width: "16%" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setSubRowOpen(!subRowOpen)}
          >
            {subRowOpen ? (
              <CiSquareMinus fontSize={25} color="#E1E1E1" />
            ) : (
              <CiSquarePlus fontSize={25} color="#E1E1E1" />
            )}
          </IconButton>

          {countries.find((el: IKeyValue) => el?.shortForm === nestedRow?._id)
            ?.name ?? nestedRow?._id}
        </TableCell>
        <TableCell sx={{ width: "11%" }}>$ {nestedRow?.custom_value}</TableCell>
        <TableCell sx={{ width: "11%" }}>
          $ {numberWithCommas(~~nestedRow?.custom_duty)}
        </TableCell>
        <TableCell sx={{ width: "12%" }}>
          {numberWithCommas(~~nestedRow?.distinct_imports)}
        </TableCell>
        <TableCell sx={{ width: "13%" }}>
          {numberWithCommas(~~nestedRow?.distinct_suppliers)}
        </TableCell>
        <TableCell sx={{ width: "14%" }}>
          {~~(nestedRow?.effective_duty_rate * 100)}%
        </TableCell>
        <TableCell sx={{ width: "15%" }}>
          {/* {numberWithCommas(~~nestedRow?.avg_shipment_value)} */}
          {numberWithCommas(
            ~~(nestedRow?.custom_value / nestedRow?.distinct_imports)
          )}
        </TableCell>
        <TableCell>$ {numberWithCommas(~~nestedRow?.duty_saved)}</TableCell>
      </TableRow>
      <SubNestedRow data={nestedRow} open={subRowOpen} />
    </>
  );
};

const SubNestedRow = ({ data, open }: { data: IKeyValue; open: boolean }) => {
  return (
    <>
      <TableRow key={data.id}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={open}
            key={Math.floor(1000 + Math.random() * 8000)}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <Table>
                <TableBody>
                  {data?.hts_numbers.map(
                    (subRows: IKeyValue, index: number) => {
                      return (
                        <TableRow key={index * 6427}>
                          <TableCell
                            style={{
                              width: "15%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            {subRows?.id}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "12%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            $ {numberWithCommas(~~subRows?.custom_value)}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "11%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            $ {numberWithCommas(~~subRows?.custom_duty)}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "12%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            {numberWithCommas(~~subRows?.distinct_imports)}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "14%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            {numberWithCommas(~~subRows?.distinct_suppliers)}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "15%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            ~~{subRows?.effective_duty_rate * 100}%
                          </TableCell>
                          <TableCell
                            style={{
                              width: "15%",
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            {/* {numberWithCommas(~~subRows?.avg_shipment_value)} */}
                            {numberWithCommas(
                              ~~(
                                subRows?.custom_value /
                                subRows?.distinct_imports
                              )
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom:
                                index === data?.hts_numbers?.length - 1
                                  ? "none"
                                  : "",
                            }}
                          >
                            $ {numberWithCommas(~~subRows?.duty_saved)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EnteredValue = ({ data, totalData }: IKeyValue) => {
  const transformData = (data: IKeyValue[]) => {
    const topLevel: IKeyValue[] = [];
    const nestedLevel: IKeyValue = {};

    if (data) {
      data.forEach((country) => {
        const topLevelDistinctSupplierValues = new Set();
        const topLevelDistinctImportValues = new Set();
        let topLevelCustomValue = 0;
        let topLevelCustomDuty = 0;
        let topLevelDistinctImports = 0;
        let topLevelDistinctSuppliers = 0;
        let topLevelEffectiveDutyRate = 0;
        let topLevelAvgShipmentValue = 0;
        let topLevelDutySaved = 0;
        let htsCount = 0;

        nestedLevel[country._id] = [];

        country.exports.forEach((exportItem: IKeyValue) => {
          const nestedDistinctSupplierValues = new Set();
          const nestedDistinctImportValues = new Set();
          let nestedCustomValue = 0;
          let nestedCustomDuty = 0;
          let nestedDistinctImports = 0;
          let nestedDistinctSuppliers = 0;
          let nestedEffectiveDutyRate = 0;
          let nestedAvgShipmentValue = 0;
          let nestedDutySaved = 0;

          exportItem.hts_numbers.forEach((hts: IKeyValue) => {
            nestedCustomValue += hts.custom_value;
            nestedCustomDuty += hts.custom_duty;
            nestedDistinctImports += hts.distinct_imports;
            nestedDistinctSuppliers += hts.distinct_suppliers;
            nestedEffectiveDutyRate += hts.effective_duty_rate;
            nestedAvgShipmentValue += hts.avg_shipment_value;
            nestedDutySaved += hts.duty_saved;
            hts.distinct_suppliers_values.forEach((supplier: string) =>
              nestedDistinctSupplierValues.add(supplier)
            );
            hts.distinct_imports_values.forEach((importValue: string) =>
              nestedDistinctImportValues.add(importValue)
            );

            topLevelCustomValue += hts.custom_value;
            topLevelCustomDuty += hts.custom_duty;
            topLevelDistinctImports += hts.distinct_imports;
            topLevelDistinctSuppliers += hts.distinct_suppliers;
            topLevelEffectiveDutyRate += hts.effective_duty_rate;
            topLevelAvgShipmentValue += hts.avg_shipment_value;
            topLevelDutySaved += hts.duty_saved;
            hts.distinct_suppliers_values.forEach((supplier: string) =>
              topLevelDistinctSupplierValues.add(supplier)
            );
            hts.distinct_imports_values.forEach((importValue: string) =>
              topLevelDistinctImportValues.add(importValue)
            );
            htsCount++;
          });

          nestedEffectiveDutyRate =
            nestedEffectiveDutyRate / exportItem.hts_numbers.length; // Average of rates
          nestedAvgShipmentValue = nestedAvgShipmentValue; // Average of values

          nestedLevel[country._id].push({
            _id: exportItem._id,
            custom_value: nestedCustomValue,
            custom_duty: nestedCustomDuty,
            distinct_imports: nestedDistinctImportValues.size,
            distinct_suppliers: nestedDistinctSupplierValues.size,
            effective_duty_rate: nestedEffectiveDutyRate,
            avg_shipment_value: nestedAvgShipmentValue,
            duty_saved: nestedDutySaved,
            hts_numbers: exportItem.hts_numbers,
          });
        });

        topLevelEffectiveDutyRate = topLevelEffectiveDutyRate / htsCount; // Average of rates
        topLevelAvgShipmentValue = topLevelAvgShipmentValue; // Average of values

        topLevel.push({
          _id: country._id,
          custom_value: topLevelCustomValue,
          custom_duty: topLevelCustomDuty,
          distinct_imports: topLevelDistinctImportValues.size,
          distinct_suppliers: topLevelDistinctSupplierValues.size,
          effective_duty_rate: topLevelEffectiveDutyRate,
          avg_shipment_value: topLevelAvgShipmentValue,
          duty_saved: topLevelDutySaved,
        });
      });
    }

    return { topLevel, nestedLevel };
  };

  const { topLevel, nestedLevel } = transformData(data?.data);
  return (
    <Card sx={{ px: 3, pt: 2, pb: 1 }}>
      {!data || !data?.data?.length ? (
        <>
          <Typography color="#000" fontSize={"1.2rem"} fontWeight={500}>
            Tariff Codes
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            color="#000"
            fontSize={"1rem"}
            fontWeight={500}
            mb={3}
          >
            No data to display
          </Typography>
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="column" mb={2}>
            <Typography
              color="#000"
              fontSize={"1.2rem"}
              fontWeight={500}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 7,
              }}
            >
              Entered Value
              <br />
            </Typography>
            <span
              style={{
                color: "#8E9494",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              by Country of Origin
            </span>
          </Box>
          <TableContainer
            className="product-table"
            component={Paper}
            style={{
              maxHeight: 440,
              borderRadius: "10px 10px 8px 8px",
              boxShadow: "none",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "0px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Country of Origin
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Customs Value
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Customs Duty
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Distinct Imports
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Distinct Suppliers
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Effective Duty Rate
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Avg Shipment Value
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "#CEDBDB",
                      color: "#116B6F",
                      padding: "5px 10px",
                      fontSize: "14px",
                    }}
                  >
                    Duty Saved
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topLevel?.map((row: IKeyValue) => (
                  <Row
                    key={row._id}
                    row={row}
                    nestedData={nestedLevel[row._id]}
                  />
                ))}
                <StyledTableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "500",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    $ {numberWithCommas(~~totalData?.data?.customs_value)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    $ {numberWithCommas(~~totalData?.data?.customs_duty)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    {numberWithCommas(~~totalData?.data?.distinct_imports)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    {numberWithCommas(~~totalData?.data?.distinct_suppliers)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    {~~totalData?.data?.effective_duty_rates}%
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    {numberWithCommas(~~totalData?.data?.avg_shipment_value)}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      background: "#CEDBDB",
                      color: "#116B6F",
                      borderBottom: "none",
                      fontWeight: "600",
                      fontSize: "1rem",
                      position: "sticky",
                      bottom: "0",
                      zIndex: "2",
                    }}
                  >
                    $ {numberWithCommas(~~totalData?.data?.total_duty_saving)}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

export default EnteredValue;
