import { Control, Controller } from "react-hook-form";
import {
  InputBaseComponentProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import React from "react";

export interface FormInputProps {
  name: string;
  control: Control<any>;
  label?: string;
  isMulti?: boolean;
  isRequired?: boolean;
  variant?: "standard" | "filled" | "outlined";
  type?: "text" | "email" | "password";
  endAdornment?: React.ReactNode;
  inputProps?: InputBaseComponentProps | undefined;
  size?: "small" | "medium";
  autoComplete?: string;
  placeholder?: string;
  onEnter?: () => void;
  color?: "primary" | "error" | "secondary" | "info" | "success" | "warning";
  id?: string;
  sx?: SxProps<Theme>;
}

export const FormInputText = ({
  name,
  control,
  label,
  endAdornment,
  inputProps,
  isMulti = false,
  isRequired = false,
  variant = "standard",
  type = "text",
  size = "small",
  autoComplete = "off",
  placeholder = "",
  onEnter,
  color = "primary",
  sx = {},
  id,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          id={id}
          placeholder={placeholder}
          autoComplete={autoComplete}
          type={type}
          helperText={error ? error.message : null}
          required={isRequired}
          size={size}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant={variant}
          multiline={isMulti}
          rows={4}
          color={color}
          InputProps={{
            endAdornment: endAdornment,
            sx: { color: "#4E535A" },
          }}
          InputLabelProps={{
            shrink: true,
            sx: { color: "#ACACAC", fontWeight: 500, fontSize: 16 },
          }}
          sx={{
            pt: 1,
            "& label.Mui-focused": {
              color: "#ACACAC",
            },
            "& input::placeholder": {
              color: "rgba(172, 172, 172)",
            },
            ...sx,
          }}
          inputProps={inputProps}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onEnter && onEnter();
            }
          }}
        />
      )}
    />
  );
};
