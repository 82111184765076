import React from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import logo_white from "../assets/images/logos/logo-white.svg";
import login_metrics_showcase from "../assets/images/login-metrics-showcase.png";
import login_duty_showcase from "../assets/images/login-duty-showcase.png";

const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid container component="main" sx={{ height: "100dvh" }}>
      <Grid
        item
        xs={0}
        sm={0}
        md={6}
        sx={{ display: { md: "block", xs: "none" } }}
      >
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          <Box
            sx={{
              width: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#031112",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* Gradient circle shadow top */}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "-70%",
                left: 0,
                borderRadius: "50%",
                background: "rgba(100, 227, 232, 0.2)",
                filter: "blur(130px)",
              }}
            />
             {/* Gradient circle shadow bottom */}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                bottom: "-70%",
                left: 0,
                borderRadius: "50%",
                background: "rgba(100, 227, 232, 0.2)",
                filter: "blur(130px)",
              }}
            />
            {/* app logo white */}
            <Box
              component="img"
              src={logo_white}
              sx={{ width: 280, mt: 8, zIndex: 1 }}
            />
            {/* floating duty utilized card */}
            <Box
              component="img"
              src={login_duty_showcase}
              sx={{
                width: "60%",
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
              }}
            />
            {/* floating metrics card */}
            <Box
              component="img"
              src={login_metrics_showcase}
              sx={{
                width: "35%",
                position: "absolute",
                left: "20%",
                top: "45%",
                borderRadius: "8px",
                boxShadow:
                  "0px 2px 53px 47px rgba(0, 0, 0, 0.12),0px 18.589px 35.561px 0px rgba(176, 183, 195, 0.14)",
              }}
            />
            <Typography
              variant="h1"
              color="#fff"
              fontWeight={600}
              fontSize="30px"
              pt={8}
              mb={8}
              zIndex={1}
            >
              Maximize your Duty Savings
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthContainer;
